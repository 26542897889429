const fetchApi = ({ url, method, body }) => {
  const csrfToken = document.querySelector(`meta[name="csrf-token"]`).content

  return window.fetch(url, {
    method,
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": `application/json`,
      Accept: `application/json`,
    },
    body: JSON.stringify(body),
  })
}

export { fetchApi }
