// the Turbo instance is automatically assigned to window.Turbo upon import:

import "@hotwired/turbo-rails"

// Turbo wouldn't scroll to the top on page visit on Firefox if
// `scroll-behavior: smooth;` is set on `html`:
// https://github.com/hotwired/turbo/issues/426
// this is the fix:

if (window.getComputedStyle(document.documentElement).scrollBehavior) {
  document.addEventListener(`turbo:load`, () => {
    document.documentElement.style.scrollBehavior = `smooth`
  })

  document.addEventListener(`turbo:before-visit`, () => {
    document.documentElement.style.scrollBehavior = `unset`
  })
}
